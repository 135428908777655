import _ from 'lodash'
import axios from '../../common/axios.js'
import Debounce from 'lodash/debounce'
import {gmapApi} from 'vue2-google-maps'
import CustomInput from '../../common/forms/Input.vue'

export default {
	data() {
		return {
			show_choices: false,
			search_address: null,
			geocoder: null,
			adresse: null,
			get_coords: false,
			results: []
		}
	},
	props: ['placeholder', 'show_coords', 'show_pointer'],
	created(){
		
	},
	mounted(){
		this.$gmapApiPromiseLazy().then(() => {
			if(this.google) {
				this.geocoder = new this.google.maps.Geocoder();
			}
		});
		document.querySelector("#app_content").addEventListener('click', this.close_results);
	},
	watch: {
		search_address: function(after, before){
			if(after && (!this.adresse || after != this.adresse.formatted_address)){
				this.search(after);
			}
		},

		//AS--> Surveille l'acquisition de coordonnées au clic sur la map
		"$store.state.ui.map_coords_pointed": function(after, before){
			let self = this
			if(this.get_coords && after){
				//AS--> Désactive la sélection de coordonnées
				this.get_coords = false
				this.$store.dispatch('set_map_coords', !this.$store.state.ui.map_get_coords)
				this.geocoder.geocode({location: after}, (results, status) => {
					if(results.length){
						self.setAddress(results[0])
					}
				})
			}
		},

		//AS--> Surveille l'acquisition de coordonnées au clic sur un marker
		"$store.state.ui.map_pin_pointed": function(after, before){
			let self = this
			if(this.get_coords && after){
				//AS--> Désactive la sélection de coordonnées
				this.get_coords = false
				this.$store.dispatch('set_map_coords', !this.$store.state.ui.map_get_coords)
				this.geocoder.geocode({location: after}, (results, status) => {
					if(results.length){
						self.setAddress(results[0])
					}
				})
			}
		}
	},
	methods: {
		search: Debounce(function(search){
			let self = this
			this.geocoder.geocode({address: search, region: 'FR'}, (results, status) => {
				self.results = results
			})
		}, 500),
		resetAddress(){
			this.search_address = null
			this.adresse = null
			this.updated()
		},
		setAddress(address){
			this.results = []
			this.adresse = address
			this.search_address = address.formatted_address
			this.updated()
		},
		updated(){
			this.$emit('update:adresse', this.adresse)
		},
		close_results(){
			this.results = []
		},
		switch_map_get_coords(){
			this.get_coords = !this.$store.state.ui.map_get_coords;
			this.$store.dispatch('set_map_coords', !this.$store.state.ui.map_get_coords);
		},
		onFocus(){
			if(this.adresse) this.search_address = this.adresse.formatted_address
		}
	},
	computed:{
		google: gmapApi,
		
	},
	components:{
		CustomInput
	}
}