export default {
	data() {
		return {
			
		}
	},
	props: ['id', 'title'],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		
	}
}