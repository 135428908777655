import _ from 'lodash'
import axios from 'axios'
import FieldAdresse from '../../maps/field_adresse.vue'
import FlexiblePolyline from '../../maps/flexible-polyline.js'
import ModaleGauche from '../../modals/modale_gauche.vue'

export default {
	data() {
		return {
			vehicule: 	this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.vehicule ? 	this.$store.state.ui.map_isoline_result.vehicule : 	'truck',
			depart: 	this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.depart ? 	this.$store.state.ui.map_isoline_result.depart : 	null,
			type: 		this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.type ? 		this.$store.state.ui.map_isoline_result.type : 		'isochrone',
			distance : 	this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.distance ? 	this.$store.state.ui.map_isoline_result.distance : 	0,
			temps_h : 	this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.temps_h ? 	this.$store.state.ui.map_isoline_result.temps_h : 	0,
			temps_min : this.$store.state.ui.map_isoline_result && this.$store.state.ui.map_isoline_result.temps_min ? 	this.$store.state.ui.map_isoline_result.temps_min : 0,
		}
	},
	props: [],
	watch: {
		depart: function(after, before){
			this.isoline = null
			this.updateGeoJson()
		},
		arrivee: function(after, before){
			this.isoline = null
			this.updateGeoJson()
		},
	},
	created(){
		
	},
	mounted(){

	},
	methods: {
		send_request(){
			if(!this.isOk) return

			let self = this
			let transportMode = this.vehicule == 'truck' ? 'truck' : this.vehicule
			let params = [
				'transportMode='+transportMode,
				'range[type]='+this.getType,
				'range[values]='+this.getValue,
				'origin='+this.depart.geometry.location.lat()+','+this.depart.geometry.location.lng()
			]
			params = params.join('---')

			axios.get('/plugin_projet/plugin_projet_iannuaire/here/isoline.router.hereapi.com---v8---isolines/'+params)

				.then(function (response) {
					self.isoline = response.data
					self.updateGeoJson()
					
				}).catch(function (error) {

				})
		},
		updateGeoJson(){
			let features = []

			if(this.isoline && this.isoline.isolines){

				let polyline = FlexiblePolyline.decode(this.isoline.isolines[0].polygons[0].outer).polyline

				let polyline_inv = []
				polyline.forEach(function(point){
					polyline_inv.push([point[1], point[0]])
				})

				features.push({
					"type": "Feature",
					"geometry": {
						"type": "Polygon",
						"coordinates": [polyline_inv]
					},
					"properties" : {}
				})
			}

			//AS--> Markers : https://sites.google.com/site/gmapsdevelopment/
			if(this.depart)
				features.push({
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [this.depart.geometry.location.lng(), this.depart.geometry.location.lat()]
					},
					"properties": {
						"style" : {
							"icon" : {
								"url" : "http://maps.google.com/mapfiles/ms/icons/green.png"
							}
						}
					}
				})

			if(features.length){
				let geojson = {
					"type": "FeatureCollection",
					"features": features,
					"properties": {
						"style": {
							"strokeColor": '#07CE6F',
							"strokeOpacity": 1,
							"fillColor": '#07CE6F',
							"fillOpacity": 0.35,
						},
						"autozoom": features.length > 1
					}
				}
				this.$store.dispatch('set_map_isoline', {geojson, result: {
						vehicule: this.vehicule,
						depart: this.depart,
						type: this.type,
						distance: parseFloat(this.distance),
						temps_h: parseFloat(this.temps_h),
						temps_min: parseFloat(this.temps_min),
						isoline: this.isoline
					}
				})
			}else{
				this.$store.dispatch('set_map_isoline', {geojson: null, result: null})
			}

		},
		reset(){
			this.vehicule  = 'truck'
			this.depart    = null
			this.temps_h   = 0
			this.temps_min = 0
			this.distance  = 0
			this.isoline   = null
			this.updateGeoJson()
			this.$refs.field_address_1.resetAddress()
		},
		getCo2(metres){
			let co2 = metres*0.000782
			return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(co2)
		}
	},
	computed:{
		isOk(){
			return this.vehicule && this.depart && this.type && (this.type == 'isochrone' ? parseFloat(this.temps_h) + parseFloat(this.temps_min) > 0 : parseFloat(this.distance) > 0)
		},
		getType(){
			return this.type == 'isochrone' ? 'time' : 'distance'
		},
		getValue(){
			return this.type == 'isochrone' ? this.temps_h*60*60 + this.temps_min*60 : this.distance * 1000
		},
		getParams(){
			let params = this.$store.state.ui.map_isoline_result

			let affiche = []
			if(this.type == 'isochrone'){
				if(this.temps_h) 	affiche.push(this.temps_h+' h')
				if(this.temps_min) 	affiche.push(this.temps_min+' min')
				affiche = affiche.join(' ')

			}else{
				affiche = this.distance + ' km'
			}
			return affiche
		}
	},
	components:{
		FieldAdresse,
		ModaleGauche
	}
}